import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useMediaQuery } from '@mui/material';
import { setDialogTypeObj } from 'Actions/outlineActions';
import AppButton, { AppButtonVariant } from 'Atomic/atoms/Button/AppButton';
import usePresentation from 'Hooks/usePresentation';
import { useTranslations } from 'Hooks/useTranslations';
import useUrl from 'Hooks/useUrl';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElType } from 'Scripts/measurementsGAHelper';
import { OverviewState } from 'Types/overviewTypes';
import React from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

interface BackToOverviewButtonProps {
    as?: AppButtonVariant,
}

const BackToOverviewButton = ( {as = AppButtonVariant.Text } : BackToOverviewButtonProps ) => {

    const history = useHistory();

    const urls = useUrl();

    const dispatch = useDispatch();

    const presentationLoading = useSelector((state: RootStateOrAny) => (state.overviewReducer as OverviewState).presentationLoading);

    const {
        setPageIndex,
    } = usePresentation();

    const toOverview = () => {

        setPageIndex(0);

        if(window.location.href.endsWith("/outline")){
            dispatch(setDialogTypeObj({dialogType: "backToMyLibrary", index: 0, isOpen: true}));
            return;
        }

        history.push(urls.presentationOverview);
    }

    const { translatePlaceholder } = useTranslations();

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("lg"));

    return (
        <AppButton
            as={as}
            responsive
            loading={presentationLoading}
            startIcon={<ArrowBackIcon />}
            onClick={() => toOverview()}
            data-testid={testIds.BUTTON_BACK_TO_OVERVIEW}
            data-elaction={DataElAction.BackToMyLibrary}
            data-eltype={DataElType.Link}
        >
            {isSmallScreen && translatePlaceholder("MY_LIBRARY")}
            {!isSmallScreen && translatePlaceholder("BACK_TO_MY_LIBRARY")}
        </AppButton>
    );
};

BackToOverviewButton.defaultProps = {
    trackAction: 'back_to_overview',
    trackScreen: 'unspecified',
    trackPosition: 'unspecified'
};

export default BackToOverviewButton;