import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import { Box, CardActionArea, Divider } from '@mui/material';
import { setPlayDialogOpen, setSelectedPresentation } from 'Actions/appActions';
import { addNewSlide } from 'Actions/quizActions';
import { AppButtonVariant } from 'Atomic/atoms/Button/AppButton';
import AppDropdownButton from 'Atomic/atoms/DropdownButton/AppDropdownButton';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import TwoCtaDialog from 'Atomic/atoms/TwoCtaDialog/TwoCtaDialog';
import CustomTooltip from 'Components/common/tooltip/CustomTooltip';
import { interactiveSlideTypesArr, itContainAnInteractiveSlide } from 'Components/helpers/slideTypeHelper';
import usePresentation from 'Hooks/usePresentation';
import { SessionRouteMatches, SessionRoutePrefix, useSessionRoutes } from 'Hooks/useSessionRoutes';
import useSlideActions from 'Hooks/useSlideActions';
import { useTranslations } from 'Hooks/useTranslations';
import { useUser } from 'Hooks/useUser';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElPosition, DataElScreen, DataElType } from 'Scripts/measurementsGAHelper';
import { AppState } from 'Types/appTypes';
import { Slide } from 'Types/presentationTypes';
import { SlideTypes } from 'Types/slideTypes';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { classes } from './style.css';

export interface PlayPresentationButtonProps {
    trackScreen?: string | null;
    trackPosition?: string | null;
    disabled?: boolean;
    presentationId: number;
    tooltip?: string | false;
}

const PlayPresentationButton = ({
    trackScreen,
    trackPosition,
    disabled,
    tooltip,
    presentationId,
}: PlayPresentationButtonProps) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { getRoute } = useSessionRoutes();
    const { attemptStartPresentation } = usePresentation();
    const { createSlide } = useSlideActions();
    const { isSendStepsUser } = useUser();
    
    const playDialogOpen = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).playDialogOpen);
    const selectedPresentation = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).selectedPresentation);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [playOrPreviewMode, setPlayOrPreviewMode] = useState<"preview"|"play"|null>(null);

    const {
		fetchPresentationDetails,
	} = usePresentation();

    const getOrderedArraySlides = (slides: Slide)=> {
        let slideSortedArray = Object.values(slides).sort((a, b) => a.slideIndex - b.slideIndex);
        slideSortedArray = slideSortedArray.map((slide, index) => ({
            ...slide,
            slideIndex: index + 1
        }));
        return slideSortedArray;
    }

    const checkIfThereIsAnInteractiveSlideWithoutExplanation = async (presentationId: number) => {
        let presentationObj = await fetchPresentationDetails(presentationId);
        presentationObj = getOrderedArraySlides(presentationObj.slides);
        let thereIsAnInteractiveSlideWithoutExplanation = false;

        if(isSendStepsUser && (itContainAnInteractiveSlide(presentationObj) && !presentationObj.find((slide: Slide)=> slide.type == SlideTypes.Explanation))){
            setIsLoading(false);
            dispatch(setPlayDialogOpen(true));
            thereIsAnInteractiveSlideWithoutExplanation = true;

        }
        return thereIsAnInteractiveSlideWithoutExplanation;
    };

    const checkBeforePlaySession = async () => {
        setPlayOrPreviewMode("play");
        const thereIsAnInteractiveSlideWithoutExplanation = await checkIfThereIsAnInteractiveSlideWithoutExplanation(presentationId);
        if(!thereIsAnInteractiveSlideWithoutExplanation) {
            playSession();
        }
    }

    const checkBeforePreviewPresentation = async () => {
        setPlayOrPreviewMode("preview");
        const thereIsAnInteractiveSlideWithoutExplanation = await checkIfThereIsAnInteractiveSlideWithoutExplanation(presentationId);
        if(!thereIsAnInteractiveSlideWithoutExplanation) {
            previewPresentation();
        }
    }

    const playSession = async () => {
        setIsLoading(true);
        await attemptStartPresentation(selectedPresentation);
        setIsLoading(false);
    };

    const previewPresentation = () => {
        history.push(getRoute({
            prefix: SessionRoutePrefix.Preview,
            presentationId: selectedPresentation,
        }, SessionRouteMatches.Intro))
    };

    const fetchAndOrderSlides = async (presentationIdentifier: number) => {
        let presentationObj = await fetchPresentationDetails(presentationIdentifier);
        presentationObj = getOrderedArraySlides(presentationObj.slides);
        return presentationObj;
    };

    const addExplanationSlide = async () => {
        let slidesArr = await fetchAndOrderSlides(selectedPresentation);
        const firstInteractiveSlideIndex = slidesArr.findIndex((slide: Slide)=> interactiveSlideTypesArr.includes(slide.type));
        const newSlide = await createSlide(selectedPresentation, SlideTypes.Explanation, firstInteractiveSlideIndex);
        dispatch(addNewSlide(newSlide[0]));
        startPlayOrPreview();
    };

    const startPlayOrPreview = () => {
        playOrPreviewMode == "play" ? playSession() : previewPresentation();
        dispatch(setPlayDialogOpen(false));
    };

    const { translatePlaceholder } = useTranslations();

    return (
        <>
            <CustomTooltip onOpen={()=> dispatch(setSelectedPresentation(presentationId))} title={tooltip || ""}>
                <Box minWidth={72}>
                    <AppDropdownButton
                        fullWidth
                        as={AppButtonVariant.Success}
                        disabled={disabled}
                        responsive
                        loading={isLoading}
                        startIcon={<PlayCircleFilledRoundedIcon
                            className={classes.startIcon}
                        />}
                        data-testid={testIds.PLAY_PRESENTATION_BUTTON}
                        dropdownComponent={(
                            <div>
                                <CardActionArea
                                    disabled={disabled}
                                    onClick={() => checkBeforePlaySession()}
                                    data-testid={testIds.PLAY_PRESENTATION_DROPDOWN_BUTTON}
                                    data-elaction={DataElAction.StartSendsteps}
                                    data-elscreen={trackScreen as DataElScreen}
                                    data-elposition={trackPosition as DataElPosition}
                                >
                                    <Box p={1}>
                                        <AppText as={AppTextVariant.BodyRegular} pb={1}>
                                            {translatePlaceholder("PLAY_PRESENTATION")}
                                        </AppText>
                                        <AppText as={AppTextVariant.CaptionLight}>
                                            {translatePlaceholder("PLAY_PRESENTATION_TO_AUDIENCE")}
                                        </AppText>
                                    </Box>
                                </CardActionArea>
                                <Box py={1} px={1}>
                                    <Divider />
                                </Box>
                                <CardActionArea
                                    disabled={disabled}
                                    data-testid={testIds.PREVIEW_PRESENTATION_DROPDOWN_BUTTON}
                                    onClick={() => checkBeforePreviewPresentation()}
                                    data-elaction={DataElAction.ShowPreview}
                                    data-elscreen={trackScreen as DataElScreen}
                                    data-elposition={trackPosition as DataElPosition}
                                >
                                    <Box p={1}>
                                        <AppText as={AppTextVariant.BodyRegular} pb={1}>
                                            {translatePlaceholder("PREVIEW_PRESENTATION")}
                                        </AppText>
                                        <AppText as={AppTextVariant.CaptionLight}>
                                            {translatePlaceholder("TEST_AND_TRY_PRESENTATION")}
                                        </AppText>
                                    </Box>
                                </CardActionArea>
                            </div>
                        )}
                    >
                        <Box mx={-.5} mr={-1} style={{ textTransform: 'uppercase'}}>
                            {translatePlaceholder("PLAY")}
                        </Box>
                    </AppDropdownButton>
                </Box>
            </CustomTooltip>
            <TwoCtaDialog 
                isOpen={playDialogOpen && presentationId == selectedPresentation}
                close={()=> dispatch(setPlayDialogOpen(false))}
                leftCtaVariant={AppButtonVariant.Success}
                title={translatePlaceholder("AN_EXPLANATION_SLIDE_IS_NEEDED") as string} 
                leftCtaIcon={<></>} 
                leftCtaLabel={translatePlaceholder("ADD_AN_EXPLANATION_SLIDE") as string} 
                leftCtaFc={()=> addExplanationSlide()}
                rightCtaLabel={translatePlaceholder(`${playOrPreviewMode == "play" ? "PLAY" : "PREVIEW"}_PRESENTATION_WITHOUT_EXPLANATION_SLIDE`)}
                rightCtaFc={()=> startPlayOrPreview()}
                dataTestIdLeftCta={testIds.ADD_EXPLANATION_SLIDE}
                dataElactionLeftCta={playOrPreviewMode == "play" ? DataElAction.PlayAddExplanationSlide : DataElAction.PreviewAddExplanationSlide}
                dataElscreenLeftCta={DataElScreen.Editor}
                dataEltypeLeftCta={DataElType.Button}
                dataTestIdRightCta={testIds.PLAY_PRESENTATION_WITHOUT_EXPLANATION_SLIDE}
                dataElactionRightCta={playOrPreviewMode == "play" ? DataElAction.PlayWithoutExplanationSlide : DataElAction.PreviewWithoutExplanationSlide}
                dataElscreenRightCta={DataElScreen.Editor}
                dataEltypeRightCta={DataElType.Button}
                dataTestIdCloseCta={testIds.CLOSE_PLAY_DIALOG}
                dataElactionCloseCta={playOrPreviewMode == "play" ? DataElAction.PlayClose : DataElAction.PreviewClose}
                dataElscreenCloseCta={DataElScreen.Editor}
                dataEltypeCloseCta={DataElType.Button}
            />
        </>
       
    );
};

export default PlayPresentationButton;