import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { setDialogTypeObj, setSlideOutlineSelected } from 'Actions/outlineActions';
import AppButton, { AppButtonVariant } from 'Atomic/atoms/Button/AppButton';
import ContainerWithBorder from 'Atomic/atoms/ContainerWithBorder/ContainerWithBorder';
import AppSpinner from 'Atomic/atoms/Spinner/AppSpinner';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import TwoCtaDialog from "Atomic/atoms/TwoCtaDialog/TwoCtaDialog";
import GenerateAiPresentationWaitDialog from 'Components/common/dialogs/generate_ai_presentation/GenerateAiPresentationWaitDialog';
import CustomTooltip from 'Components/common/tooltip/CustomTooltip';
import { interactiveSlideTypesArr, itContainAnInteractiveSlide } from 'Components/helpers/slideTypeHelper';
import useOutline from 'Hooks/useOutline';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElScreen, DataElType } from 'Scripts/measurementsGAHelper';
import { ISlideOutline } from 'Types/outlineTypes';
import { Slide } from 'Types/presentationTypes';
import { SlideTypes } from 'Types/slideTypes';
import React, { ChangeEvent, useEffect } from "react";
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RewriteTypeEnum } from '../RewriteTypeEnum';
import SlideOutline from "../SlideOutline/SlideOutline";
import { getFlatStepperDataFromLocalStorage } from '../helper';
import { classes } from "./style.css";

const defaultSlideOutline = {title: "", content: "", type: SlideTypes.Content};

const OutlineLogicContainer = ()=> {
    const dispatch = useDispatch();
    const history = useHistory();
    const inputObjValues = useSelector((state: RootStateOrAny) => state.generatePresentationReducer.flatInputObjValues);
    const slideOutlineSelected = useSelector((state: RootStateOrAny) => (state.outlineReducer).slideOutlineSelected);
    const dialogTypeObj = useSelector((state: RootStateOrAny) => (state.outlineReducer).dialogTypeObj);
    const { translatePlaceholder } = useTranslations();

    const {
        outline,
        rewriteSuggestions,
        isLoading,
        isLoadingRewrite,
        generatePresentationLoading,
        setOutline,
        generateOutline,
        generateRewriteOutline,
        generateOutlineProcess,
    } = useOutline();

    useEffect(()=> {
        dispatch(setSlideOutlineSelected(0));
    },[]);

    useEffect(()=> {
        generateOutline(inputObjValues);
        getOutlineFromLocalStorage();
    },[inputObjValues]);

    useEffect(()=> {
        if(rewriteSuggestions.length > 0) updateOutlineWithRewritedSuggestion();
    },[rewriteSuggestions]);

    useEffect(()=> {
        if(outline?.length == 0 && inputObjValues.flatInputObjValues) addSlideOutline(-1);
        if(outline?.length > 0) localStorage.setItem("outlineData", JSON.stringify([...outline]));
        if(inputObjValues.flatInputObjValues) localStorage.setItem("flatStepperData", JSON.stringify({...inputObjValues.flatInputObjValues}));
    },[outline]);

    const getOutlineFromLocalStorage = ()=> {
        if(!inputObjValues.flatInputObjValues){
            const outlineData = localStorage.getItem("outlineData");
            if (outlineData) {
                const outlineDataObj = JSON.parse(outlineData);
                setOutline(outlineDataObj);
            }
        };
    };

    const updateOutlineWithRewritedSuggestion = ()=> {
        let updatedOutline = [...outline];
        updatedOutline[dialogTypeObj.index] = {
            ...updatedOutline[dialogTypeObj.index],
            ["title"]: rewriteSuggestions[0].title,
            ["content"]: rewriteSuggestions[0].content
        }
        setOutline([...updatedOutline]);
    }

    const setInputValueByName = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number)=> {
        if(!e) return;
        outline[index][e.target.name as keyof ISlideOutline] = e.target.value;
        setOutline([...outline]);
    }

    const addSlideOutline = (index: number)=> {
        outline.splice(index + 1, 0, {...defaultSlideOutline});
        dispatch(setSlideOutlineSelected(index + 1));
        setOutline([...outline]);
    }

    const configureAndToggleDialog = (dialogType: "ai"|"remove"|"", index: number)=> {
        dispatch(setDialogTypeObj({dialogType: dialogType, index: index, isOpen: false}));
        if(dialogType == "ai" && outline[index]["content"].length == 0 && outline[index]["title"].length == 0){
            if(document.activeElement){
                document.activeElement.blur();
            }

            completeWithAi(index);
        } else {

            dispatch(setDialogTypeObj({dialogType: dialogType, index: index, isOpen: true}));
        };
    }

    const deleteSlideOutline = ()=> {
        outline.splice(dialogTypeObj.index, 1);
        setOutline([...outline]);
        dispatch(setDialogTypeObj({dialogType: "", index: dialogTypeObj.index, isOpen: false}));
    }

    const completeWithAi = (index: number|undefined)=> {
        let rewriteOutline = [...outline];

        rewriteOutline[index? index : dialogTypeObj.index] = {
            ...rewriteOutline[index? index : dialogTypeObj.index],
            ['rewrite']: RewriteTypeEnum.Both
        };
        rewriteOutline = {
            ['outline']: [...rewriteOutline],
            ...inputObjValues.flatInputObjValues
        }
        generateRewriteOutline(rewriteOutline);
        dispatch(setDialogTypeObj({dialogType: "", index: index ? index : dialogTypeObj.index, isOpen: false}));
    }

    const backToMyLibrary = ()=> {
        history.push('/my-library');
    };

    const generatePresentation = (checkObj?: {avoidCheck: boolean, updatedOutline?: ISlideOutline[]})=> {
        if(itContainAnInteractiveSlide(outline) && !outline.find((slide: Slide)=> slide.type == "explanation") && (checkObj?.avoidCheck == false || checkObj == undefined)){
            dispatch(setDialogTypeObj({dialogType: "addExplanationSlide", index: 0, isOpen: true}));
            return;
        }

        let finalOutline = checkObj?.updatedOutline ? checkObj.updatedOutline : [...outline];
        if(inputObjValues.flatInputObjValues){
            finalOutline = {
                ['outline']: [...finalOutline],
                ...inputObjValues.flatInputObjValues
            }
        } else {
            finalOutline = {
                ['outline']: [...finalOutline],
                ...getFlatStepperDataFromLocalStorage()
            }
        }

        if(!finalOutline.sourceType){
            finalOutline = {
                ...finalOutline,
                ['sourceType']: "keywords",
            }
        }
        if(!finalOutline.keywords){
            finalOutline = {
                ...finalOutline,
                ['keywords']: finalOutline.title,
            }
        }
        if(!finalOutline.outputType){
            finalOutline = {
                ...finalOutline,
                ['outputType']: "presentation",
            }
        }
        generateOutlineProcess(finalOutline);
    }

    const onDragEnd = (result: DropResult) => {
        if(document.activeElement){
            document.activeElement.blur();
        }
        const { source, destination } = result;

        if (!destination) {
          return;
        }
        let draggedOutline = [...outline];
        const [movedItem] = draggedOutline.splice(source.index, 1);
        draggedOutline.splice(destination.index, 0, movedItem);
        if(slideOutlineSelected != null) dispatch(setSlideOutlineSelected(destination.index));
        setOutline([...draggedOutline]);
    };

    const onToggleSlideOutlineSelected = (selectedIndex: number, fromToggleLine: boolean = false)=> {
        if(selectedIndex != slideOutlineSelected){
            dispatch(setSlideOutlineSelected(selectedIndex));
            return
        }
        if(fromToggleLine && selectedIndex == slideOutlineSelected) dispatch(setSlideOutlineSelected(null));
    }

    const addExplanationSlide = (index: number)=> {
        const updatedOutline = [...outline];
        updatedOutline.splice(index, 0, {title: "", content: "", type: "explanation"});
        generatePresentation({avoidCheck: true, updatedOutline: updatedOutline});
    };

    const addExplanationSlideAndCreatePresentation = async ()=> {
        const firstInteractiveSlideIndex = outline.findIndex((slide: Slide)=> interactiveSlideTypesArr.includes(slide.type));
        addExplanationSlide(firstInteractiveSlideIndex - 1);
    };
    const createPresentationWithoutExplanationSlide = ()=> {
        generatePresentation({avoidCheck: true});
    };

    const dialogsContext = [
        {
            dialogType: "remove",
            title:  `${translatePlaceholder("ARE_YOU_SURE_YOU_WANT_TO_REMOVE_SLIDE_NUMBER")} "${dialogTypeObj.index + 1}" ?`,
            leftCtaIcon: <DeleteIcon />,
            leftCtaLabel: translatePlaceholder("REMOVE"),
            leftCtaFc: deleteSlideOutline,
            variant: AppButtonVariant.Success,
            dataTestidLeftCta: testIds.REMOVE_SLIDE,
            dataElactionLeftCta: DataElAction.DeleteSlideRemove,
            dataElpositionLeftCta: dialogTypeObj.index,
            dataElscreenLeftCta: DataElScreen.Popup,
            dataEltypeLeftCta: DataElType.Button,
            dataTestidRightCta: testIds.CANCEL_REMOVE_SLIDE,
            dataElactionRightCta: DataElAction.DeleteSlideCancel,
            dataElpositionRightCta: dialogTypeObj.index,
            dataElscreenRightCta: DataElScreen.Popup,
            dataEltypeRightCta: DataElType.Button,
            dataTestIdCloseCta: testIds.CLOSE_REMOVE_SLIDE,
            dataElactionCloseCta: DataElAction.DeleteSlideClose,
            dataElscreenCloseCta: DataElScreen.Popup,
            dataEltypeCloseCta: DataElType.Button,
            dataElPositionCloseCta: dialogTypeObj.index,
        },
        {
            dialogType: "ai",
            title: translatePlaceholder("ARE_YOU_SURE_YOU_WANT_TO_COMPLETE_WITH_AI"),
            leftCtaIcon: <AutoAwesomeOutlinedIcon  fontSize="small"/>,
            leftCtaLabel: translatePlaceholder("COMPLETE_WITH_AI"),
            leftCtaFc: completeWithAi,
            variant: AppButtonVariant.Success,
            dataTestidLeftCta: testIds.COMPLETE_WITH_AI,
            dataElactionLeftCta: DataElAction.WriteWithAiConfirm,
            dataElpositionLeftCta: dialogTypeObj.index,
            dataElscreenLeftCta: DataElScreen.Popup,
            dataEltypeLeftCta: DataElType.Button,
            dataTestidRightCta: testIds.CANCEL_COMPLETE_WITH_AI,
            dataElactionRightCta: DataElAction.WriteWithAiCancel,
            dataElpositionRightCta: dialogTypeObj.index,
            dataElscreenRightCta: DataElScreen.Popup,
            dataEltypeRightCta: DataElType.Button,
            dataTestIdCloseCta:  testIds.CLOSE_COMPLETE_WITH_AI,
            dataElactionCloseCta: DataElAction.WriteWithAiClose,
            dataElscreenCloseCta: DataElScreen.Popup,
            dataEltypeCloseCta: DataElType.Button,
            dataElPositionCloseCta: dialogTypeObj.index,
        },
        {
            dialogType: "backToMyLibrary",
            title: translatePlaceholder("ARE_YOU_SURE_YOU_WANT_TO_RETURN_TO_MY_LIBRARY"),
            leftCtaIcon: <ArrowBackIcon  fontSize="small"/>,
            leftCtaLabel: translatePlaceholder("BACK_TO_MY_LIBRARY"),
            leftCtaFc: backToMyLibrary,
            variant: AppButtonVariant.Success,
            dataTestidLeftCta: testIds.BACK_TO_MY_LIBRARY,
            dataElactionLeftCta: DataElAction.BackToMyLibraryConfirm,
            dataElscreenLeftCta: DataElScreen.Popup,
            dataEltypeLeftCta: DataElType.Button,
            dataTestidRightCta: testIds.CANCEL_BACK_TO_MY_LIBRARY,
            dataElactionRightCta: DataElAction.BackToMyLibraryCancel,
            dataElscreenRightCta: DataElScreen.Popup,
            dataEltypeRightCta: DataElType.Button,
            dataTestIdCloseCta:  testIds.CLOSE_BACK_TO_MY_LIBRARY,
            dataElactionCloseCta: DataElAction.BackToMyLibraryClose,
            dataElscreenCloseCta: DataElScreen.Popup,
            dataEltypeCloseCta: DataElType.Button,
        },
        {
            dialogType: "addExplanationSlide",
            title: translatePlaceholder("AN_EXPLANATION_SLIDE_IS_NEEDED"),
            leftCtaIcon: <></>,
            leftCtaLabel: translatePlaceholder("ADD_AN_EXPLANATION_SLIDE"),
            leftCtaFc: ()=> addExplanationSlideAndCreatePresentation(),
            rightCtaLabel: translatePlaceholder("CREATE_PRESENTATION_WITHOUT_EXPLANATION_SLIDE"),
            rightCtaFc: ()=> createPresentationWithoutExplanationSlide(),
            variant: AppButtonVariant.Success,
            dataTestidLeftCta: testIds.ADD_EXPLANATION_SLIDE,
            dataElactionLeftCta: DataElAction.CreateAddExplanationSlide,
            dataElscreenLeftCta: DataElScreen.OutlineEditor,
            dataEltypeLeftCta: DataElType.Button,
            dataTestidRightCta: testIds.CREATE_PRESENTATION_WITHOUT_EXPLANATION_SLIDE,
            dataElactionRightCta: DataElAction.CreateWithoutExplanationSlide,
            dataElscreenRightCta: DataElScreen.OutlineEditor,
            dataEltypeRightCta: DataElType.Button,
            dataTestIdCloseCta:  testIds.CLOSE_ADD_EXPLANATION_SLIDE,
            dataElactionCloseCta: DataElAction.CreateClose,
            dataElscreenCloseCta: DataElScreen.OutlineEditor,
            dataEltypeCloseCta: DataElType.Button,
        }
    ];

    const dialogContext = dialogsContext.find((context)=> context.dialogType == dialogTypeObj.dialogType);

    // Disable the generate presentation button if the outline is empty or if the outline has only one slide with no title and content
    const disableGeneratePresentation = outline?.length > 0
        ? (outline?.length == 1 && outline[0].title == "" && outline[0].content == "")
        : true

    return  isLoading
    ?
        <>
            <ContainerWithBorder>
                <div className={classes.outlinePageTitle}>
                    <AppText as={AppTextVariant.BodySmallLight}>{translatePlaceholder("OUTLINE_EDITOR")}
                        <CustomTooltip title={translatePlaceholder('OUTLINE_EDITOR_TOOLTIP')} placement={"top"}>
                            <InfoOutlinedIcon className={classes.infoIcon}/>
                        </CustomTooltip>
                    </AppText>
                </div>
                <div className={classes.outlineLoaderMsgContainer}>
                    <AppSpinner/>
                    <AppText className={classes.outlineLoaderMsg} variant="caption">
                        {translatePlaceholder("OUTLINE_EDITOR_GENERATE_WAIT_MSG")}
                    </AppText>
                </div>
            </ContainerWithBorder>
            <div className={classes.generatePresentationFooter}>
                <AppButton
                    className={classes.generatePresentationButton}
                    onClick={()=> generatePresentation()}
                    disabled={true}
                    as={AppButtonVariant.Success}>
                        {translatePlaceholder("GENERATE_PRESENTATION")}
                </AppButton>
            </div>
        </>
    :
        <>
            <ContainerWithBorder>
                <div className={classes.outlinePageTitle}>
                    <AppText as={AppTextVariant.BodySmallLight}>{translatePlaceholder("OUTLINE_EDITOR")}
                        <CustomTooltip title={translatePlaceholder('OUTLINE_EDITOR_TOOLTIP')} placement={"top"}>
                            <InfoOutlinedIcon className={classes.infoIcon}/>
                        </CustomTooltip>
                    </AppText>
                </div>
                <DragDropContext
                    onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                        {
                            outline?.map((slideOutline, index)=> {
                                return (
                                    <Draggable key={`draggable-${index+1}`} draggableId={`id-${index+1}`} index={index} isDragDisabled={isLoadingRewrite}>
                                        {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <SlideOutline
                                                key={`slide-outline-${index + 1}`}
                                                title={slideOutline?.title}
                                                content={slideOutline?.content}
                                                type={slideOutline?.type}
                                                index={index}
                                                isLoading={isLoadingRewrite}
                                                dialogTypeObj={dialogTypeObj}
                                                onBlurValue={setInputValueByName}
                                                addSlideOutline={addSlideOutline}
                                                deleteSlideOutline={configureAndToggleDialog}
                                                completeWithAi={configureAndToggleDialog}
                                                isSelected={slideOutlineSelected == index}
                                                onToggleSlideOutlineSelected={onToggleSlideOutlineSelected}
                                            />
                                        </div>
                                    )}
                                    </Draggable>
                                )
                            })
                        }
                        </div>
                    )}
                    </Droppable>
                </DragDropContext>
            </ContainerWithBorder>
            <div className={classes.generatePresentationFooter}>
                <AppButton
                    className={classes.generatePresentationButton}
                    onClick={()=> generatePresentation()}
                    disabled={disableGeneratePresentation}
                    as={AppButtonVariant.Success}
                    data-testid={testIds.GENERATE_PRESENTATION}
                    data-elaction={DataElAction.ConfirmAIGenerate}
                    data-elscreen={DataElScreen.OutlineEditor}
                    data-eltype={DataElType.Button}
                >
                        {translatePlaceholder("GENERATE_PRESENTATION")}
                </AppButton>
            </div>
            <GenerateAiPresentationWaitDialog
                open={generatePresentationLoading}
            />
            <TwoCtaDialog
                isOpen={dialogTypeObj?.isOpen}
                close={()=> dispatch(setDialogTypeObj({dialogType: "", index: 0, isOpen: false}))}
                title={dialogContext?.title ?? ""}
                leftCtaIcon={dialogContext?.leftCtaIcon || <></>}
                leftCtaLabel={dialogContext?.leftCtaLabel ?? ""}
                leftCtaFc={dialogContext?.leftCtaFc!}
                leftCtaVariant={dialogContext?.variant}
                rightCtaLabel={dialogContext?.rightCtaLabel}
                rightCtaFc={dialogContext?.rightCtaFc}
                dataTestIdLeftCta={dialogContext?.dataTestidLeftCta}
                dataElactionLeftCta={dialogContext?.dataElactionLeftCta}
                dataElpositionLeftCta={dialogContext?.dataElpositionLeftCta}
                dataElscreenLeftCta={dialogContext?.dataElscreenLeftCta}
                dataEltypeLeftCta={dialogContext?.dataEltypeLeftCta}
                dataTestIdRightCta={dialogContext?.dataTestidRightCta}
                dataElactionRightCta={dialogContext?.dataElactionRightCta}
                dataElpositionRightCta={dialogContext?.dataElpositionRightCta}
                dataElscreenRightCta={dialogContext?.dataElscreenRightCta}
                dataEltypeRightCta={dialogContext?.dataEltypeRightCta}
                dataTestIdCloseCta={dialogContext?.dataTestIdCloseCta}
                dataElactionCloseCta={dialogContext?.dataElactionCloseCta}
                dataElscreenCloseCta={dialogContext?.dataElscreenCloseCta}
                dataEltypeCloseCta={dialogContext?.dataEltypeCloseCta}
            />
        </>
}
export default OutlineLogicContainer;