import Fade from '@mui/material/Fade';
import DarkTransitionAnimation from 'Components/play/templates/geometry_template/animations/components/dark/DarkTransitionAnimation';
import ParticipantCounter from 'Components/play/templates/geometry_template/shared_components/participant_counter/ParticipantCounter';
import GeometrySessionDetails from 'Components/play/templates/geometry_template/slides/explanation_slide/session_details/GeometrySessionDetails';
import SessionLoader from 'Components/session/SessionLoader';
import ParticipantJoinContainer from 'Components/session/participant_container/ParticipantJoinContainer';
import { BaseTypes } from 'Components/types/helpers/Slide';
import { extractSlidesByType } from 'Components/types/helpers/slideTypeHelper';
import { useSession } from 'Hooks/useSession';
import { SessionRouteMatches, useSessionRoutes } from 'Hooks/useSessionRoutes';
import { openMessageRound } from 'Scripts/requests';
import { getSortedSlideKeysBySlideIndex } from 'Scripts/slideHelper';
import { AppState } from 'Types/appTypes';
import { PlayState } from 'Types/playTypes';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { classes } from './style.css';

export enum ViewType {
	EDITOR = "EDITOR",
	PLAY = "PLAY"
};

interface IExplanationSlideShapeComponent {
	from: ViewType
};

const ExplanationSlideShapeComponent = ({from}: IExplanationSlideShapeComponent) => {
	const sessionData = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).sessionData);
	const liveWebsocket = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).liveWebsocket);
	const playSlides = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).playSlides);
	const {userSessionInfo} = useSession();
	const history = useHistory();
	const { getRoute, presentationId, currentSlideKey } = useSessionRoutes();
	
	const [transitionPlaying, setTransitionPlaying] = useState(false);

	const startQuiz = () => {
		setTransitionPlaying(true);
		openMessageWhichArentLimitedToSlides();
		setTimeout(goToNextSlide, 500);
	}

	const openMessageWhichArentLimitedToSlides = () => {
		const messageRounds = extractSlidesByType(playSlides, BaseTypes.MessageRound);
		Object.keys(messageRounds || {}).forEach(messageRoundId => {
			if(messageRounds?.[messageRoundId]?.limitToSlide === 0) {
				openMessageRound(messageRoundId);
			}
		})
	}

	const goToNextSlide = () => {
		const sortedBySlideIndex = getSortedSlideKeysBySlideIndex(playSlides);
		const currentSlideIndex = sortedBySlideIndex.indexOf(currentSlideKey as number);
		const nextSlideIndex = sortedBySlideIndex[currentSlideIndex + 1];

		history.push(getRoute({
			presentationId: presentationId as number,
			currentSlideKey: nextSlideIndex
		}, SessionRouteMatches.ActiveSlide));
	}

	return (
		<Fade timeout={{ enter: 250 }} in={true}>
			<div className={classes.fadeWrapper}>
				{from == ViewType.PLAY &&  <ParticipantJoinContainer />}
				{from == ViewType.PLAY && <DarkTransitionAnimation show={transitionPlaying} />}
				<div className={classes.wrapper}>
					{!(sessionData && liveWebsocket) && from !== ViewType.EDITOR && <SessionLoader error={!(sessionData && liveWebsocket)} />}
					{(sessionData && liveWebsocket) && from !== ViewType.EDITOR ? 
						<GeometrySessionDetails
							attemptStart={() => startQuiz()}
							sessionDetails={sessionData}
						/>:
						<GeometrySessionDetails
							attemptStart={() => {}}
							sessionDetails={{ internetAddress: userSessionInfo?.responseUrl, internetKeyword: userSessionInfo?.responseCode }}
						/>
					}
				</div>
				<div className={classes.participantCounterBox}>
					<ParticipantCounter />
				</div>
			</div>
		</Fade>
	);
};

export default ExplanationSlideShapeComponent;