import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { Box, IconButton, InputBase, InputBaseProps } from '@mui/material';
import ButtonLoader from 'Components/common/util/button_loader/ButtonLoader';
import { useTranslations } from 'Hooks/useTranslations';
import { DataElType, TestIdAttributes, TrackingAttributes } from 'Scripts/measurementsGAHelper';
import React from 'react';
import { classes } from './style.css';


interface EditInputProps extends TrackingAttributes, TestIdAttributes, InputBaseProps {
    loading?: boolean;
    onClick?: ()=> void;
}

const EditInput = React.forwardRef<HTMLInputElement, EditInputProps>(({
    loading,
    onClick,
    ...props
}: EditInputProps, ref) => {
    const { translatePlaceholder } = useTranslations();

    const {
        "data-testid": dataTestId,
        "data-elaction": dataElAction,
        "data-elposition": dataElPosition,
        "data-elscreen": dataElScreen,
        "data-eltype": dataElType,
        inputProps,
        ...otherProps
    } = props;

    return (
            <InputBase
                fullWidth
                data-eltype={DataElType.Input}
                classes={{
                    disabled: classes.inputDisabled,
                    error: classes.inputError,
                }}
                inputProps={{
                    ref,
                    className: classes.input,
                    "data-testid": dataTestId,
                    "data-elaction": dataElAction,
                    "data-elposition": dataElPosition,
                    "data-elscreen": dataElScreen,
                    "data-eltype": dataElType,
                    ...inputProps,
                }}
                {...otherProps}
                startAdornment={(
                    <>
                        {(loading || props.startAdornment) && (
                            <Box px={1} className={classes.startAdornmentContainer}>
                                {loading && (
                                    <ButtonLoader inline dark />
                                )}
                                {!loading && props.startAdornment}
                            </Box>
                        )}
                    </>
                )}
                endAdornment={onClick &&(
                    <Box px={1}>
                        <IconButton title={translatePlaceholder("EDIT")} onClick={onClick} className={classes.button}>
                            <DriveFileRenameOutlineOutlinedIcon className={classes.icon}/>
                        </IconButton>
                    </Box>
                )}
            />
    );
});

export default EditInput;