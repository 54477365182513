
export enum TestIDs {
    // Login
    USER_LOGIN_BUTTON,
    LOGIN_EMAIL,
    LOGIN_PASSWORD,
    LOGIN_SUBMIT_BUTTON,
    LOGIN_PANEL,

    // General
    BUTTON_BACK_TO_OVERVIEW,
    REMOVE_PRESENTATION_CONFIRM,
    PAGE_TITLE_TEXT,
    UPGRADE_MY_ACCOUNT,
    GENERAL_SETTINGS_ICON,
    ADVANCED_OPTIONS_TOGGLE,
    HEADER_LOGO,
    SENDSTEPS_BUTTON,

    // Presentation overview
    PRESENTATION_OVERVIEW,
    PRESENTATION_LINK,
    ADD_PRESENTATION_BUTTON,
    LIST_SEARCH_FILTER,
    PRESENTATION_ITEM,
    PRESENTATION_START_BUTTON,
    LIST_PRESENTATION_OPTIONS,
    LIST_PRESENTATION_OPTIONS_DELETE,

    // Presentation summary

    // Presentation editor
    TOOLTIP_CLOSE,
    EDITOR_PRESENTATION_TITLE,
    EXPORT_TO_POWERPOINT,
    ADD_SLIDE_BUTTON,
    ITEM_OPEN_ENDED,
    ITEM_WORDCLOUD,
    ITEM_MP_CHOICE,
    ITEM_POINTS,
    ITEM_QUIZ,
    ITEM_OPEN_ENDED_QUIZ,
    ITEM_RIGHT_ORDER,
    ITEM_QA_SESSION,
    ITEM_INFO_SLIDE,
    ITEM_CONTENT_SLIDE_CONTENT,
    CONTENT_SLIDE_CONTENT,
    START_PRESENTATION,
    SETTINGS_PRESENTATION,

    EDITOR_SLIDE_OPTION_TEXT,
    EDITOR_SLIDE_OPTION_CORRECT,
    EDITOR_SLIDE_OPTION_DELETE,
    EDIT_LOGIN_CODE,

    LAYOUT_DEFAULT,
    LAYOUT_IMAGE_ON_TOP,
    LAYOUT_IMAGE_ON_BOTTOM,
    LAYOUT_IMAGE_ON_LEFT,
    LAYOUT_IMAGE_ON_RIGHT,
    LAYOUT_IMAGE_ON_LEFT_PADDED,
    LAYOUT_IMAGE_ON_RIGHT_PADDED,
    LAYOUT_IMAGE_ON_LEFT_SMALL,
    LAYOUT_IMAGE_ON_RIGHT_SMALL,
    LAYOUT_IMAGE_ON_BACKGROUND,

    SELECT_LAYOUT_DEFAULT,
    SELECT_LAYOUT_IMAGE_ON_TOP,
    SELECT_LAYOUT_IMAGE_ON_BOTTOM,
    SELECT_LAYOUT_IMAGE_ON_LEFT,
    SELECT_LAYOUT_IMAGE_ON_RIGHT,
    SELECT_LAYOUT_IMAGE_ON_LEFT_PADDED,
    SELECT_LAYOUT_IMAGE_ON_RIGHT_PADDED,
    SELECT_LAYOUT_IMAGE_ON_LEFT_SMALL,
    SELECT_LAYOUT_IMAGE_ON_RIGHT_SMALL,
    SELECT_LAYOUT_IMAGE_ON_BACKGROUND,

    // Change slide button/text

    // Multiple license overlay
    MULTI_LICENSE_ITEM,
    MULTI_LICENSE_BUY_OWN,

    // Change slide button/text
    DUPLICATE_SLIDE_BUTTON,
    CHANGE_SLIDE_TYPE,
    QUESTION_TYPE_HEADER,
    // Slide types
    CHANGE_SLIDE_TO_QUIZ,
    CHANGE_SLIDE_TO_MPC,
    CHANGE_SLIDE_TO_Q_AND_A,
    CHANGE_SLIDE_TO_TITLE_AND_TEXT,
    CHANGE_SLIDE_TO_WORDCLOUD,
    CHANGE_SLIDE_TO_OPEN_ENDED,
    CHANGE_SLIDE_TO_POINTS,

    // Autosave
    AUTOSAVE_STATE_TEXT,

    // Slide options (Right drawer)
    SLIDE_OPTION_POINTS_500,
    SLIDE_OPTION_POINTS_1000,
    SLIDE_OPTION_DISPLAY_LEADERBOARD,
    SLIDE_OPTION_NUMERIC_RESULTS,
    SLIDE_OPTION_PERCENTAGE_RESULTS,
    SLIDE_OPTION_TIME_LIMIT_TOGGLE,
    SLIDE_OPTION_POINTS_QUESTION,
    SLIDE_OPTION_SHOW_AS_WORDCLOUD,
    SLIDE_OPTION_DOWNLOAD_WORDLCOUD_PNG,

    MAXIMUM_PARTICIPANT_RESPONSE_BUTTON,

    // Presentation styles
    CHANGE_PRESENTATION_STYLE,
    CHANGE_PRESENTATION_STYLE_ITEM,
    PRESENTATION_STYLE_TEXT,
    CLOSE_STYLE_PREVIEW,
    DIALOG_PREVIEW,

    // Pagination
    PAGINATION_NEXT_BUTTON,
    PAGINATION_PREV_BUTTON,
    PAGINATION_PAGE_BUTTON,
    SELECT_PAGE_SIZE,
    PAGE_SIZE_10,
    PAGE_SIZE_25,
    PAGE_SIZE_100,
    PAGE_SIZE_ALL,

    // File preview
    FILE_UPLOAD_BUTTON,
    FILE_UPLOAD_DND_CONTAINER,
    FILE_UPLOAD_CLEAR_BUTTON,
    FILE_UPLOAD_DELETE_BUTTON,
    FILE_SELECT_BUTTON,
    FILE_USE_ENTIRE_IMAGE_BUTTON,
    FILE_SAVE_BUTTON,
    FILE_MINI_PREVIEW,
    FILE_UPLOAD_CLOSE,

    FILE_UPLOAD_TAB,
    VIDEOURL_UPLOAD_TAB,
    FILE_BROWSE_GIF_TAB,
    FILE_BROWSE_IMAGES_TAB,
    FILE_BROWSE_GIF_SEARCH_FIELD,
    VIDEOURL_COMPONENT,
    VIDEOURL_INPUT,

    OVERVIEW_EDIT_PRESENTATION,

    SETTINGS_CONTAINER,
    /* Teams page ids */
    TEAM_MENU_ITEM,
    TEAM_MANAGE_USERS_SETTINGS_ITEM,
    CUSTOMER_SUPPORT_MENU_ITEM,
    CUSTOM_SUPPORT_MENU_ITEM,
    TEAM_JOIN_REQUESTS_ITEM,

    TEAM_MY_SUBSCRIPTIONS_ABOUT_PRIMARY_PLAN,
    TEAM_MY_SUBSCRIPTIONS_ABOUT_OTHER_PLAN,
    TEAM_MY_SUBSCRIPTIONS_CHANGE_PLAN,

    TEAM_JOIN_LICENSE_TOGGLE,
    TEAM_LICENSE_NAME_FIELD,
    TEAM_LICENSE_NAME_EDIT_BUTTON,
    TEAM_AUTOMATICALLY_ACCEPT_REQUEST_TOGGLE,

    /* Manage users page ids */
    MANAGE_USERS_SEARCH_ICON,
    MANAGE_USERS_SEARCH_FIELD,
    MANAGE_USERS_ADD_USER_BUTTON,

    /* License page ids */
    LICENSE_ADD_USER_NAME_FIELD,
    LICENSE_ADD_USER_LASTNAME_FIELD,
    LICENSE_ADD_USER_DEPARTMENT_FIELD,
    LICENSE_ADD_USER_EMAIL_FIELD,
    LICENSE_ADD_USER_ROLE_RADIO_USER,
    LICENSE_ADD_USER_ROLE_RADIO_MANAGER,
    LICENSE_ADD_USER_ROLE_RADIO_ADMIN,
    LICENSE_ADD_USER_CONFIM_BUTTON,

    LICENSE_USER_OPTIONS_MENU,
    LICENSE_USER_EDIT_OPTION,
    LICENSE_USER_DOWNGRADE_OPTION,
    LICENSE_USER_DELETE_OPTION,

    LICENSE_USER_DOWNGRADE_CONFIRM,
    LICENSE_USER_DELETE_CONFIRM,
    LICENSE_USER_DELETE_CONFIRM_FIELD,
    LICENSE_USER_DOWNLOAD_EXCEL,

    /* Identification question prefix to use with the following QUESTION_* ids */
    IDENTIFICATION_QUESTION_PREFIX,

    /* Generic TestIds used in combination with a prefix */
    QUESTION_INPUT,
    QUESTION_INPUT_EMPTY,
    QUESTION_CLOSE,
    QUESTION_TYPE_SELECT,
    QUESTION_TYPE_DROPDOWN_TEXT,
    QUESTION_TYPE_DROPDOWN_RADIO,
    QUESTION_TYPE_DROPDOWN_MPC,
    QUESTION_REQUIRED,
    QUESTION_DELETE,
    QUESTION_OPTION_INPUT,
    QUESTION_OPTION_INPUT_EMPTY,
    QUESTION_OPTION_DELETE,

    // Welcome new user popup
    WELCOME_CLOSE_BUTTON,
    CREATE_FROM_SCRATCH_WELCOME_TOUR,
    CREATE_FROM_SCRATCH_WELCOME,
    INSPIRATION_TEMPLATES_WELCOME,
    WELCOME_TOUR,

    // Rating
    RATING_REMIND_ME_LATER_BUTTON,

    // Results
    PRESENTATION_RESULTS_BUTTON,
    DELETE_RESULTS_BUTTON,
    DELETE_RESULTS_CONFIRM_BUTTON,
    RESULT_QUESTION_ITEM,
    RESULT_PIE_CHART_DISPLAY,
    RESULT_BAR_CHART_DISPLAY,
    RESULT_COLUMN_CHART_DISPLAY,
    RESULT_NUMERIC_DISPLAY,
    RESULT_PERCENTAGE_DISPLAY,
    RESULT_DOWNLOAD,
    RESULT_DOWNLOAD_PDF,
    RESULT_DOWNLOAD_CSV,
    RESULT_DOWNLOAD_XLSX,

    RESULT_TAB_FILTER_BY_ANSWER,
    RESULT_TAB_FILTER_BY_PERSON,

    ACCOUNT_SETTINGS_INPUT_FIRST_NAME,
    ACCOUNT_SETTINGS_INPUT_LAST_NAME,
    ACCOUNT_SETTINGS_INPUT_EMAIL,
    ACCOUNT_SETTINGS_INPUT_DEPARTMENT_NAME,
    ACCOUNT_SETTINGS_TOGGLE_EMAIL_RESULTS,

    BILLING_SETTINGS_POSTAL_CODE,
    BILLING_SETTINGS_ADDRESS,
    BILLING_SETTINGS_CITY,
    BILLING_SETTINGS_COUNTRY,
    BILLING_SETTINGS_COUNTRY_ITEM,
    BILLING_SETTINGS_ORGANISATION,
    BILLING_SETTINGS_VAT_ID,
    BILLING_SETTINGS_TIMEZONE,
    BILLING_SETTINGS_TIMEZONE_ITEM,
    BILLING_SETTINGS_UPDATE,

    BILLING_TABLE_ORDER_DETAILS,
    BILLING_INVOICE_PRINT_PAGE,

    RENEWAL_STATUS,
    ACTIVATE_RENEWAL,
    RENEWAL_REASON_SELECT,
    RENEWAL_REASON_SELECT_ITEM,
    CONFIRM_CANCELATION_BUTTON,
    CANCEL_CANCELATION_BUTTON,

    CUSTOMER_SUPPORT_BUTTON_GOTCHA,

    SIGNUP_STEPPER_SEGMENT,
    SIGNUP_STEPPER_SEGMENT_NEXT_BUTTON,

    SIGNUP_STEPPER_ROLE,
    SIGNUP_STEPPER_ROLE_NEXT_BUTTON,

    SIGNUP_STEPPER_CREATION_REASON,
    SIGNUP_STEPPER_CREATION_REASON_SUBMIT,

    SIGNUP_STEPPER_SKIP_BUTTON,

    JOIN_REQUESTS_ACCEPT_REQUEST,
    JOIN_REQUESTS_REJECT_REQUEST,

    FORGOT_PASSWORD_BUTTON,
    FORGOT_PASSWORD_EMAIL_FIELD,
    FORGOT_PASSWORD_REQUEST_RESET_BUTTON,
    FORGOT_PASSWORD_RESEND_EMAIL_BUTTON,
    FORGOT_PASSWORD_GOT_IT_BUTTON,

    RESET_PASSWORD_NEW_PASSWORD_FIELD,
    RESET_PASSWORD_NEW_PASSWORD_REPEAT_FIELD,
    RESET_PASSWORD_NEW_PASSWORD_SUBMIT_BUTTON,

    TOGGLE_SHOW_QUIZ_RESULTS_ONLY,

    SAVE_LICENSE_LOGO_BUTTON,
    DELETE_ACCOUNT,
    DELETE_ACCOUNT_CONFIRM,

    NEWS_FEED_ICON,
    NEWS_FEED_ICON_BADGE,
    NEWS_FEED_CONTAINER,
    CLOSE_NEWS_FEED_ICON,

    START_BUTTON,
    ADD_SLIDE_TAB,
    ADD_SLIDE_GET_INSPIRATION,
    ADD_SLIDE_AI_CONTENT_CREATOR,
    ADD_SLIDE_CLOSE_PANEL,

    INSPIRATION_SLIDE_TYPE_FILTER_OPEN_ENDED,
    INSPIRATION_SLIDE_TYPE_FILTER_MPC,
    INSPIRATION_SLIDE_TYPE_FILTER_QUIZ,
    INSPIRATION_SLIDE_TYPE_FILTER_WORDCLOUD,
    INSPIRATION_SLIDE_TYPE_FILTER_QA,
    INSPIRATION_SLIDE_TYPE_FILTER_POINTS,

    INSPIRATION_SLIDES_SLIDE_ITEM,
    INSPIRATION_SLIDES_CATEGORY,
    INSPIRATION_SLIDES_PREVIEW,

    SETTINGS_PRESENTATION_TITLE,

    PRESS_TO_START_SESSION,

    SESSIONRUN_VIEW_RESULT,
    SESSIONRUN_RESULT_DELETE,
    SESSIONRUN_RESULT_DELETE_CONFIRM,
    SESSIONRUN_RESULT_DELETE_CANCEL,

    MOBILE_LAYOUT_TAB_SLIDES,
    MOBILE_LAYOUT_TAB_EDITOR,
    MOBILE_LAYOUT_TAB_OPTIONS,
    MOBILE_LAYOUT_PLAY,

    DOWNLOAD_ADD_IN_LINK,


    // Inspiration
    MINIATURE_SLIDE,
    SEE_ALL_INSPIRATION_TEMPLATES,
    INSPIRATION_TEMPLATES_SLIDE,
    INSPIRATION_TEMPLATES_FROM_SCRATCH,

    DELETE_EMPTY_PRESENTATION_CONFIRM,
    DELETE_EMPTY_PRESENTATION_CANCEL,
    UPVOTE_BADGE,

    NEW_DESIGN_FEEDBACK_CLOSE_BANNER,
    NEW_DESIGN_FEEDBACK_URL,
    LANGUAGE_DROPDOWN,
    LANGUAGE_DROPDOWN_ITEM,
    LANGUAGE_APP_DROPDOWN,
    LANGUAGE_APP_DROPDOWN_ITEM,

    MISSING_FEATURE_DIALOG_CLOSE,

    SETTINGS_INTEGRATION_DROPDOWN,

    SETTINGS_INTEGRATION_WEBAPP,
    SETTINGS_INTEGRATION_POWERPOINT,
    SETTINGS_INTEGRATION_MSTEAMS,
    USER_MENU_MY_ACCOUNT_SETTINGS,
    USER_MENU_MY_PLAN,
    USER_MENU_BILLING,
    USER_MENU_TAKE_TOUR,
    USER_MENU_CUSTOMER_SUPPORT,
    USER_MENU_LOG_OUT,

    EDIT_TEMPLATE_POPUP_CLOSE,
    CONTINUE_EDITING_TEMPLATE,

    MEDIA_COMPONENT_FRAME,

    SLIDE_TITLE,
    CONTROLS_STOP_SESSION,
    STOP_SESSION_CONFIRM,
    RESUME_SESSION,

    SHOW_PREVIEW,
    CLOSE_PREVIEW,

    EDITOR_CONTAINER,
    KEEP_WORKING_BUTTON,
    MISSING_MULTIPLE_FEATURES_DIALOG_CLOSE,
    MISSING_FEATURE_DIALOG,
    MISSING_MULTIPLE_FEATURE_DIALOG,
    MISSING_FEATURE_TEXT,
    LIST_PRESENTATION_OPTIONS_SHARE,

    SHARE_PRESENTATION_GENERATE,
    SHARE_PRESENTATION_UNSHARE,
    SHARE_PRESENTATION_INPUT,

    ADD_QUESTION_BUTTON,
    ADD_QUESTION_TAB,

    CHANGE_SLIDE_TO_TITLE_SLIDE,

    CHANGE_SLIDE_TO_IMAGE_SLIDE,
    CHANGE_SLIDE_TO_VIDEO_SLIDE,
    CHANGE_SLIDE_TO_QUOTE_SLIDE,
    CHANGE_SLIDE_TO_TABLE_SLIDE,
    CHANGE_SLIDE_TO_LINE_GRAPH_SLIDE,
    CHANGE_SLIDE_TO_COUNTDOWN_SLIDE,
    CHANGE_SLIDE_TO_TITLE_AND_SUBTITLE_SLIDE,
    CHANGE_SLIDE_TO_LEADERBOARD_SLIDE,
    CHANGE_SLIDE_TO_PODIUM_SLIDE,
    CHANGE_SLIDE_TO_EXPLANATION_SLIDE,

    ADD_SLIDE_QUIZ,
    ADD_SLIDE_MPC,
    ADD_SLIDE_WORDCLOUD,
    ADD_SLIDE_POINTS,
    ADD_SLIDE_OPEN_ENDED,
    ADD_SLIDE_Q_AND_A,

    ADD_SLIDE_TITLE,
    ADD_SLIDE_TITLE_AND_SUBTITLE,
    ADD_SLIDE_TITLE_AND_TEXT,
    ADD_SLIDE_IMAGE,
    ADD_SLIDE_VIDEO,
    ADD_SLIDE_QUOTE,
    ADD_SLIDE_TABLE,
    ADD_SLIDE_LINE_GRAPH,
    ADD_SLIDE_COUNTDOWN,
    ADD_SLIDE_LEADERBOARD,
    ADD_SLIDE_PODIUM,
    ADD_SLIDE_EXPLANATION,

    QUESTION_TYPE_QUIZ,
    QUESTION_TYPE_MPC,
    QUESTION_TYPE_POINTS,
    QUESTION_TYPE_Q_AND_A,
    QUESTION_TYPE_OPEN_ENDED,
    QUESTION_TYPE_WORDCLOUD,

    SLIDE_TYPE_TITLE,
    SLIDE_TYPE_TITLE_AND_SUBTITLE,
    SLIDE_TYPE_TITLE_AND_TEXT,
    SLIDE_TYPE_IMAGE,
    SLIDE_TYPE_VIDEO,
    SLIDE_TYPE_QUOTE,
    SLIDE_TYPE_COUNTDOWN,

    IMAGE_SLIDE_MEDIA_AREA,

    SLIDE_EXAMPLE,

    PRESENTATION_SUMMARY_NAVIGATE_PREVIOUS,
    PRESENTATION_SUMMARY_NAVIGATE_NEXT,

    CLAIM_SHARED_PRESENTATION,
    SHARED_PRESENTATION_ITEM,

    PRESENTATION_TEMPLATE_CATEGORY,
    APP_BACKDROP,

    SPEAKER_NOTES_FIELD,

    SLIDE_OPTIONS_TAB_LAYOUT,
    SLIDE_OPTIONS_TAB_CONFIGURATION,
    SLIDE_TYPE_TAB_QUESTIONS,
    SLIDE_TYPE_TAB_SLIDES,

    EDITOR_SLIDE_CONTENT,
    EDITOR_SLIDE_CONTENT_LAYOUT_PREVIEW,
    VIDEO_SLIDE_SESSION,

    PLAY_PRESENTATION_BUTTON,
    PLAY_PRESENTATION_DROPDOWN_BUTTON,
    PREVIEW_PRESENTATION_DROPDOWN_BUTTON,

    AI_EDIT_TOOLBAR_BUTTON,
    AI_REWRITE_BUTTON,
    AI_REWRITE_EXTEND_BUTTON,
    AI_REWRITE_REDUCE_BUTTON,
    AI_REWRITE_CHANGE_TONE_MENU,

    CONTROLS_GO_FORWARD,
    CONTROLS_GO_BACK,
    UPLOAD_DOCUMENT_BUTTON,
    SELECT_DOCUMENT_BUTTON,
    CLOSE_DOCUMENT_UPLOAD_DIALOG,
    DOCUMENT_UPLOAD_DROP_ZONE,

    SSO_BUTTON_GOOGLE,
    SSO_BUTTON_LINKEDIN,

    GENERATE_PRESENTATION_FROM_OUTLINE,
    EDIT_OUTLINE_ITEM,
    DELETE_OUTLINE_ITEM,
    CONFIRM_OUTLINE_TITLE,
    RELOAD_OUTLINE_ITEM,
    UNDO_OUTLINE_CHANGE,
    REDO_OUTLINE_CHANGE,

    FEEDBACK_BUTTON,
    RATING_DIALOG_NEXT_BUTTON,
    RATING_DIALOG_RATING_VALUE,
    RATING_DIALOG_RATING_TEXT_FIELD,
    CLOSE_RATING_DIALOG_ICON,

    INITIAL_PROMPT_SUBMIT_BUTTON,
    INITIAL_PROMPT_FIELD_SMALL,
    INITIAL_PROMPT_FIELD_LARGE,
    STEPPER_CONTINUE_BUTTON,
    AI_STEPPER_LANGUAGE_DROPDOWN,
    AI_STEPPER_LANGUAGE,
    AI_STEPPER_USAGE,
    AI_STEPPER_LENGTH,
    AI_STEPPER_TITLE,

    AI_TYPE_SELECT_PRESENTATION,
    AI_TYPE_SELECT_QUIZ,

    MISSING_FEATURE_EXTRA_BUTTON,

    MULTIPLE_VOTE_BUTTON,
    MAX_PARTICIPANT_COUNT,
    BACK_TO_EDITOR,
    PARTICIPANTS_CAN_EDIT_ANSWERS_TOGGLE,
    PARTICIPANT_IDENTIFICATION_TOGGLE,
    PARTICIPANT_EMOJI_TOGGLE,
    PARTICIPANT_TXT_RESPONSES_TOGGLE,

    AI_CONTENT_CREATOR_CARD,
    AI_CONTENT_CREATOR_BUTTON,
    AI_CONTENT_CREATOR_KEYWORDS_INPUT,

    AI_CONTENT_CREATOR_TO_SELECT_TYPE_STEP,
    AI_CONTENT_CREATOR_TO_SELECT_SLIDES_STEP,
    AI_CONTENT_CREATOR_BACK_TO_SELECT_TYPE_STEP,
    AI_CONTENT_CREATOR_BACK_TO_KEYWORDS_STEP,

    AI_CONTENT_CREATOR_SLIDE_TEMPLATE,
    AI_CONTENT_CREATOR_NEXT_PAGE_BUTTON,
    AI_CONTENT_CREATOR_PREVIOUS_PAGE_BUTTON,
    AI_CONTENT_CREATOR_ADD_TO_PRESENTATION_BUTTON,
    AI_CONTENT_CREATOR_SELECT_TYPE,

    EDITOR_SETTINGS_CONTAINER,
    NOTIFICATION_POPUP_CONTENTS,

    TABLE_SLIDE_CELL,
    TABLE_OPTIONS_BUTTON,
    TABLE_CELL_OPTIONS_BUTTON,
    TABLE_CLEAR_BUTTON,

    TABLE_CELL_OPTIONS_ADD_COLUMN_LEFT,
    TABLE_CELL_OPTIONS_ADD_COLUMN_RIGHT,
    TABLE_CELL_OPTIONS_ADD_ROW_ABOVE,
    TABLE_CELL_OPTIONS_ADD_ROW_BELOW,
    TABLE_CELL_OPTIONS_DELETE_ROW,
    TABLE_CELL_OPTIONS_DELETE_COLUMN,

    TABLE_OPTIONS_HEADER_ROW,
    TABLE_OPTIONS_HEADER_COLUMN,

    DOCUMENT_OUTLINE_VIEW_BACK_TO_EDIT_TITLE,
    GENERATE_PRESENTATION_FROM_TEMPLATES,

    SCROLL_TO_SECTION,
    GENERATE_PRESENTATION,
    REMOVE_SLIDE,
    CANCEL_REMOVE_SLIDE,
    COMPLETE_WITH_AI,
    CANCEL_COMPLETE_WITH_AI,
    BACK_TO_MY_LIBRARY,
    CANCEL_BACK_TO_MY_LIBRARY,
    ONCHANGE_SLIDE_TYPE,
    ADD_SLIDE_OUTLINE,
    REGENERATE_OUTLINE,
    REGENERATE_OUTLINE_CANCEL,
    REGENERATE_OUTLINE_CONFIRM,
    OUTLINE_TITLE,
    OUTLINE_SUBJECT,
    OUTLINE_LANGUAGE,
    OUTLINE_NUMBER_OF_SLIDES,
    OUTLINE_AMOUNT_OF_TEXT,
    OUTLINE_INCLUDE_SPEAKER_NOTES,
    OUTLINE_ADD_INTERACTIVE_QUESTIONS,
    OUTLINE_WRITE_FOR,
    OUTLINE_TONE_OF_VOICE,
    REMOVE_SLIDE_OUTLINE_ICON,
    COMPLETE_WITH_AI_ICON,
    REGENERATE_OUTLINE_CLOSE,
    CLOSE_REMOVE_SLIDE,
    CLOSE_COMPLETE_WITH_AI,
    CLOSE_BACK_TO_MY_LIBRARY,
    ADD_EXPLANATION_SLIDE,
    CREATE_PRESENTATION_WITHOUT_EXPLANATION_SLIDE,
    CLOSE_ADD_EXPLANATION_SLIDE,
    PLAY_PRESENTATION_WITHOUT_EXPLANATION_SLIDE,
    CLOSE_PLAY_DIALOG,
}

/**
 * Reduce enum to Object containing key/values which match the enum keys
 *
 * e.g.: testIds.SOME_TEST_ID evaluates to "SOME_TEST_ID"
 *
 * */
export const testIds = Object.keys(TestIDs).reduce((testIdValues, testID) => {

    if (isNaN(Number(testID))) {

        testIdValues[testID] = testID;
    }

    return testIdValues;

}, {}) as { [key: string]: TestIDs };
