import { OutlineActionTypes } from "Reducers/outlineReducer";

export const setOutlineObj = (outline: []) => ({
    type: OutlineActionTypes.SetOutline,
    payload: outline,
});

export const setIsLoading = (isLoading: boolean) => ({
    type: OutlineActionTypes.SetIsLoading,
    payload: isLoading,
});

export const setSlideOutlineSelected = (slideOutlineSelected: number|null) => ({
    type: OutlineActionTypes.SetSlideOutlineSelected,
    payload: slideOutlineSelected,
});

export const setDialogTypeObj = (dialogTypeObj: {dialogType: string, index: number, isOpen: boolean}) => ({
    type: OutlineActionTypes.SetDialogTypeObj,
    payload: dialogTypeObj,
});