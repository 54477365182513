import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid } from "@mui/material";
import { setSlideOutlineSelected } from 'Actions/outlineActions';
import ContainerWithBorder from 'Atomic/atoms/ContainerWithBorder/ContainerWithBorder';
import AppSpinner from 'Atomic/atoms/Spinner/AppSpinner';
import AppText, { AppTextVariant } from "Atomic/atoms/Text/AppText";
import CustomTooltip from 'Components/common/tooltip/CustomTooltip';
import { getSlidePropertiesByType } from 'Components/types/helpers/slideTypeHelper';
import useOutline from 'Hooks/useOutline';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElScreen, DataElType } from 'Scripts/measurementsGAHelper';
import { ISlideOutline } from 'Types/outlineTypes';
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { classes } from "./style.css";

const isTextOverflowing = (id: string) => {
    const element = document.getElementById(id);
    if(!element) return false
    return element.scrollHeight > element.clientHeight;
}

const Overview = ()=> {
    const dispatch = useDispatch();
    const outline = useSelector((state: RootStateOrAny) => (state.outlineReducer).outline);

    const {
        isLoading,
    } = useOutline();

    const { translatePlaceholder } = useTranslations();

    const scrollToSection = (id: string, index: number) => {
        const element = document.getElementById(id);
        if (element) {
            setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 100);

        }
        dispatch(setSlideOutlineSelected(index));
    }

    return (
        <ContainerWithBorder>
            {isLoading
                ?
                <>
                    <AppText mb={"10px"} as={AppTextVariant.BodySmallLight}>{translatePlaceholder("OVERVIEW")}
                        <CustomTooltip title={translatePlaceholder('OUTLINE_OVERVIEW_TOOLTIP')} placement={"top"}>
                            <InfoOutlinedIcon className={classes.infoIcon}/>
                        </CustomTooltip>
                    </AppText>
                    <AppSpinner/>
                </>
                :
                <>
                    <div className={classes.overviewPageTitle}>
                        <AppText mb={"10px"} as={AppTextVariant.BodySmallLight}>{translatePlaceholder("OVERVIEW")}
                            <CustomTooltip title={translatePlaceholder('OUTLINE_OVERVIEW_TOOLTIP')} placement={"top"}>
                                <InfoOutlinedIcon className={classes.infoIcon}/>
                            </CustomTooltip>
                        </AppText>
                    </div>
                    <Grid container rowGap={1}>
                        {outline?.map((outlineSlide: ISlideOutline, index: number) => (
                            <Fragment key={`overview-${index}`}>
                                <Grid className={classes.type} item xs={2}>
                                    <CustomTooltip title={translatePlaceholder(outlineSlide.type.toUpperCase())} placement={"top"}>
                                        <div className={classes.typeIcon}>
                                            {getSlidePropertiesByType(outlineSlide?.type).slideAssets.outlineIcon}
                                        </div>
                                    </CustomTooltip>
                                </Grid>
                                <Grid container className={classes.title} item xs={10}>
                                    <Grid container>
                                        <Grid item xs={2} md={1.5}>
                                            <AppText className={classes.titleParagraph} as={AppTextVariant.TertiaryBgSmall}>{index + 1}.</AppText>
                                        </Grid>
                                        <Grid item xs={9} md={8}>
                                            {isTextOverflowing(`overview-ellipsis-${index}`) ?
                                                <CustomTooltip title={outlineSlide?.title ? outlineSlide.title : "--"} placement={"top"}>
                                                    <a
                                                        data-testid={testIds.SCROLL_TO_SECTION}
                                                        data-elaction={DataElAction.OverviewTitle}
                                                        data-elposition={index}
                                                        data-elscreen={DataElScreen.OutlineOverview}
                                                        data-eltype={DataElType.Link}
                                                        className={classes.link}
                                                        onClick={()=> scrollToSection(`slide-anchor-${index}`, index)}
                                                    >
                                                        <AppText id={`overview-ellipsis-${index}`} className={classes.titleParagraph} as={AppTextVariant.TertiaryBgSmall}>{outlineSlide?.title ? outlineSlide.title : "--"}</AppText>
                                                    </a>
                                                </CustomTooltip> :
                                                    <a
                                                        data-testid={testIds.SCROLL_TO_SECTION}
                                                        data-elaction={DataElAction.OverviewTitle}
                                                        data-elposition={index}
                                                        data-elscreen={DataElScreen.OutlineOverview}
                                                        data-eltype={DataElType.Link}
                                                        className={classes.link}
                                                        onClick={()=> scrollToSection(`slide-anchor-${index}`, index)}
                                                    >
                                                            <AppText id={`overview-ellipsis-${index}`} className={classes.titleParagraph} as={AppTextVariant.TertiaryBgSmall}>{outlineSlide?.title ? outlineSlide.title : "--"}</AppText>
                                                    </a>
                                            }
                                        </Grid>
                                        <Grid className={classes.ellipsisContainer} item xs={1} md={0.5}>
                                            <AppText style={{display: isTextOverflowing(`overview-ellipsis-${index}`)? "flex" : "none"}} className={classes.ellipsis} as={AppTextVariant.TertiaryBgSmall}>...</AppText>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        ))}
                    </Grid>
                </>

            }
        </ContainerWithBorder>
    );
}
export default Overview;
