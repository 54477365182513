import CropFreeIcon from '@mui/icons-material/CropFree';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import QRComponent from 'Atomic/molecules/QRComponent/QRComponent';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { bindKeyboardEvent, unbindKeyboardEvent } from 'Scripts/eventBinding';
import { nextNavigationKeys } from 'Scripts/keyboardHelper';
import { DataElAction, DataElPosition, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import { PlayState } from 'Types/playTypes';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import SessionSMSInfo from '../../../shared_components/session_login_info/SessionSMSInfo';
import { classes } from './style.css';



let GeometrySessionDetails = ({ sessionDetails, attemptStart }) => {

	const { presentationStyle } = usePresentationStyles();

	const playSlides = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).playSlides);


	const { translate } = useTranslations();

	const { internetAddress, internetKeyword } = sessionDetails;

	const QRUrl = `https://${internetAddress}/${internetKeyword}`;


	const handleKeys = (e: KeyboardEvent) => {

		if (nextNavigationKeys.includes(e.key)) {

			attemptStart();

			unbindKeyboardEvent(handleKeys);
		}
	}

	useEffect(() => {

		bindKeyboardEvent(handleKeys)

		return () => {

			unbindKeyboardEvent(handleKeys)
		}

	}, [])

	const participantsWithLoginCodeOnly = sessionDetails.participantsWithLoginCodeOnly ?? false;

	return (
		<Grid
			container
			className={classes.fullSize}
			direction="column"
			justifyContent="center"
			alignItems="center">
			<Grid item>
				<Typography variant="h3" className={classes.joinText} sx={{
					color: presentationStyle?.textColor,

				}}>
					{!participantsWithLoginCodeOnly && translate('GO_TO_SITE_AND_LOGIN', [
						<span className={classes.highlightText}>{sessionDetails.internetAddress}</span>,
						<Typography className={classes.responseCodeText} variant="h2" sx={{
							color: presentationStyle?.textColor,
						}}>
							{sessionDetails.internetKeyword}
						</Typography>
					])}
					{participantsWithLoginCodeOnly && translate('GO_TO_SITE_AND_LOGIN', [
						<span className={classes.highlightText}>{sessionDetails.internetAddress}</span>,
						<span>{translate("YOUR_PERSONAL_LOGIN")} </span>,
					])}
				</Typography>
			</Grid>
			{participantsWithLoginCodeOnly &&
				<Grid item>
					<div className={classes.qrContentFiller}>
					</div>
				</Grid>
			}
			{!participantsWithLoginCodeOnly &&
				<Grid item>
					<div className={classes.qrContent}>
						<QRComponent
							QRCodeText={QRUrl}
						/>
						<Typography className={classes.qrText} variant="body1" sx={{
							color: presentationStyle?.textColor,
						}}>
							{translate('QR_INSTRUCTIONS', [
								<CropFreeIcon className={classes.scanIcon} />
							])}
						</Typography>
					</div>
				</Grid>
			}
			{!participantsWithLoginCodeOnly &&
				<Box
					className={classes.smsInfoContainer}
					sx={{
						color: presentationStyle?.textColor,
					}}>
					<SessionSMSInfo showPrefix={false} />
				</Box>
			}
			<Grid item style={{ height: 64 }}>
				<Grow in={!isEmpty(playSlides)}>
					<Box
						data-testid={testIds.PRESS_TO_START_SESSION}
						className={classes.spaceButton}
						onClick={() => attemptStart()}
						{...renderingGAAttr(DataElAction.QuizControlsPlay, DataElPosition.Bottom, DataElScreen.Play, DataElType.Button)}
						sx={{
							color: presentationStyle?.textColor,
							border: `2px solid ${presentationStyle?.textColor}`
						}}
					>
						{translate('PRESS_TO_START')}
					</Box>
				</Grow>
			</Grid>
		</Grid>
	);
};

export default GeometrySessionDetails
