import AddIcon from '@mui/icons-material/Add';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { IconButton, InputBase, MenuItem, Select, Typography } from '@mui/material';
import EditInput from 'Atomic/atoms/EditInput/EditInput';
import AppSpinner from 'Atomic/atoms/Spinner/AppSpinner';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import CustomTooltip from 'Components/common/tooltip/CustomTooltip';
import { interactiveSlideTypesArr, notInteractiveSlideTypesArr } from 'Components/helpers/slideTypeHelper';
import { themeVars } from 'Components/theme/vanillaTheme.css';
import { getSlidePropertiesByType } from 'Components/types/helpers/slideTypeHelper';
import { useTranslations } from 'Hooks/useTranslations';
import { TestIDs } from 'Scripts/cypressTestIds';
import { DataElAction, DataElScreen, DataElType } from 'Scripts/measurementsGAHelper';
import { TDialogTypeObj } from 'Types/outlineTypes';
import React, { ChangeEvent, useEffect, useRef } from "react";
import { classes } from "./style.css";

interface ISlideOutline {
    title?: string;
    content?: string;
    type: string;
    index: number;
    isLoading: boolean;
    dialogTypeObj: TDialogTypeObj;
    onBlurValue: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number)=> void;
    addSlideOutline: (index: number)=> void;
    deleteSlideOutline: (dialogType: "ai" | "remove", index: number)=> void;
    completeWithAi: (dialogType: "ai" | "remove", index: number)=> void;
    isSelected: boolean;
    onToggleSlideOutlineSelected: (index: number)=> void;
}

const maxTitleLenght = 200;
const maxContentLenght = 5000;

const SlideOutline = ({
    title = "",
    content = "",
    type,
    index,
    isLoading,
    dialogTypeObj,
    onBlurValue,
    addSlideOutline,
    deleteSlideOutline,
    completeWithAi,
    isSelected,
    onToggleSlideOutlineSelected,
}: ISlideOutline)=> {

    const { translatePlaceholder } = useTranslations();
    interface IFormObj {
        title: string
        content: string,
        type: string,
    }

    const [formObj, setFormObj] = React.useState<IFormObj>({
        title: "",
        content: "",
        type: "",
    });

    useEffect(()=> {
        setFormObj({
            title: title,
            content: content,
            type: type,
        });
    }, [
        title,
        content,
        type
    ]);

    useEffect(()=> {
        if(isSelected && inputRef.current){
            inputRef.current?.focus();
        }
    }, [isSelected]);

    const inputRef = useRef(null);

    const onChangeValue = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=> {
        let value = e.target.value;
        if(e.target.name == "title" && e.target.value.length > maxTitleLenght || e.target.name == "content" && e.target.value.length > maxContentLenght){
            value = value.slice(0, e.target.name == "title" ? maxTitleLenght : maxContentLenght);
        };

        setFormObj({
            ...formObj,
            [e.target.name]: value,
        });
    };

    const onBlur  = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=> {
        onBlurValue(e, index);
    };

    return(
        <div id={`slide-anchor-${index}`} style={{paddingTop: isSelected ? "41px" : "0"}} className={title == "" && content == "" ? classes.slideOutlineAnimation : ""}>
            <div className={classes.container}>
                <div className={classes.dragIconContainer}>
                    <IconButton
                        size='small'
                        className={classes.dragAndDropButton}
                        onClick={()=> {}}
                    >
                        <DragIndicatorOutlinedIcon className={classes.dragIcon}/>
                    </IconButton>
                </div>
                <div
                    style={{
                        borderColor: isSelected  ? themeVars.color.selectedColor : themeVars.color.defaultBg,
                        height: isSelected  ? "auto" : "59px"
                    }}
                    className={classes.slideOutlineBox}
                >
                    <div className={classes.flexBox}>
                        <div
                            className={classes.flexLeftBox}
                            onClick={()=> onToggleSlideOutlineSelected(index)}
                        >
                            <AppText as={AppTextVariant.FifthBg}>{index + 1}</AppText>
                        </div>
                        <div
                            className={classes.flexCenterBox}
                            onClick={()=> onToggleSlideOutlineSelected(index)}
                        >
                            <div className={classes.inputContainer}>
                                <EditInput
                                    ref={inputRef}
                                    className={classes.nameInput}
                                    name="title"
                                    value={formObj.title}
                                    placeholder={translatePlaceholder("INSERT_TITLE")+"..."}
                                    disabled={isLoading && index == dialogTypeObj.index}
                                    onChange={(e)=> onChangeValue(e)}
                                    onBlur={(e)=> onBlur(e)}
                                />
                                <AppText
                                    className={classes.titleExeed}
                                    as={AppTextVariant.TertiaryBgSmall}
                                    style={{color: formObj?.title.length == maxTitleLenght && isSelected ? themeVars.color.aiBg : themeVars.color.tertiaryBg }}
                                >
                                    {`${formObj?.title.length} / ${maxTitleLenght}`}
                                </AppText>
                            </div>
                        </div>
                        <div className={classes.flexRightBox}>
                            <CustomTooltip title={translatePlaceholder(formObj.type.toUpperCase())} placement={"top"}>
                                <Select
                                    value={formObj.type}
                                    name="type"
                                    onChange={(e) => onChangeValue(e)}
                                    onBlur={(e)=> onBlur(e)}
                                    input={<InputBase className={classes.dropdown}/>}
                                    data-testid={TestIDs.ONCHANGE_SLIDE_TYPE}
                                    data-elaction={`${DataElAction.ChangeSlideTo}${formObj.type}`}
                                    data-elposition={index}
                                    data-elscreen={DataElScreen.OutlineEditor}
                                    data-eltype={DataElType.Dropdown}
                                >
                                    <AppText className={classes.slideTypeLabel} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("SLIDES")}</AppText>
                                    <hr className={classes.hr}/>
                                    {notInteractiveSlideTypesArr.map((t, i)=> {
                                        return(
                                            <MenuItem
                                                key={t + i}
                                                value={t}
                                                className={classes.typeItem}
                                            >
                                                {getSlidePropertiesByType(t)?.slideAssets?.outlineIcon}
                                                <Typography>
                                                    {translatePlaceholder(t?.toUpperCase())}
                                                </Typography>
                                            </MenuItem>
                                        )
                                    })}
                                    <AppText className={classes.slideTypeLabel} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("QUESTIONS")}</AppText>
                                    <hr className={classes.hr}/>
                                    {interactiveSlideTypesArr.map((t, i)=> {
                                        return(
                                            <MenuItem
                                                key={t + i}
                                                value={t}
                                                className={classes.typeItem}
                                            >
                                                {getSlidePropertiesByType(t)?.slideAssets?.outlineIcon}
                                                <Typography>
                                                    {translatePlaceholder(t?.toUpperCase())}
                                                </Typography>
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </CustomTooltip>
                        </div>
                    </div>
                    <div className={classes.inputBoxContainer}>
                        <AppText as={AppTextVariant.FifthBgSmall}>{translatePlaceholder("SLIDE_STORYLINE")}</AppText>
                        <div className={classes.inputContainer}>
                            <EditInput
                                className={classes.contentInput}
                                multiline
                                name="content"
                                value={formObj.content}
                                placeholder={translatePlaceholder("INSERT_STORYLINE")+"..."}
                                disabled={isLoading && index == dialogTypeObj.index}
                                onChange={(e) => onChangeValue(e)}
                                onBlur={(e)=> onBlur(e)}
                            />
                             <AppText
                                className={classes.contentExeed}
                                as={AppTextVariant.TertiaryBgSmall}
                                style={{color: formObj?.content.length == maxContentLenght && isSelected ? themeVars.color.aiBg : themeVars.color.tertiaryBg }}
                             >
                                {`${formObj?.content.length} / ${maxContentLenght}`}
                            </AppText>
                        </div>
                    </div>
                    <a className={classes.dragLine} onClick={()=> onToggleSlideOutlineSelected(index, true)}></a>
                </div>
                <div>
                    {isLoading && index == dialogTypeObj.index ?
                        <span className={classes.rewriteLoader}><AppSpinner/></span>  :
                            !isLoading  ? <CustomTooltip title={translatePlaceholder("COMPLETE_WITH_AI")} placement={"top"}>
                                <div className={classes.iconContainer}>
                                    <IconButton
                                        size='small'
                                        onClick={()=> completeWithAi("ai", index)}
                                        className={classes.iconButton}
                                        data-testid={TestIDs.COMPLETE_WITH_AI_ICON}
                                        data-elaction={DataElAction.WriteWithAi}
                                        data-elposition={index}
                                        data-elscreen={DataElScreen.OutlineEditor}
                                        data-eltype={DataElType.Icon}
                                    >
                                        <AutoAwesomeOutlinedIcon className={classes.icon} fontSize="small"/>
                                    </IconButton>
                                </div>
                            </CustomTooltip> : <></>}
                    {!isLoading && <CustomTooltip title={translatePlaceholder("DELETE")} placement={"top"}>
                        <div className={classes.iconContainer}>
                            <IconButton
                                size='small'
                                onClick={()=> deleteSlideOutline("remove", index)}
                                className={classes.iconButton}
                                data-testid={TestIDs.REMOVE_SLIDE_OUTLINE_ICON}
                                data-elaction={DataElAction.DeleteSlide}
                                data-elposition={index}
                                data-elscreen={DataElScreen.OutlineEditor}
                                data-eltype={DataElType.Icon}
                            >
                                <DeleteOutlineIcon className={classes.icon}/>
                            </IconButton>
                        </div>
                    </CustomTooltip>}
                    {isLoading && index != dialogTypeObj.index && <div className={classes.emptyContainer}></div>}
                </div>
            </div>
            {!isLoading && <div className={classes.plusIconBox}>
                <CustomTooltip title={translatePlaceholder("ADD")} placement={"top"}>
                    <a
                        onClick={()=> addSlideOutline(index)}
                        data-testid={TestIDs.ADD_SLIDE_OUTLINE}
                        data-elaction={DataElAction.AddOutlineSlide}
                        data-elposition={index + 1}
                        data-elscreen={DataElScreen.OutlineEditor}
                        data-eltype={DataElType.Button}
                    >
                        <AddIcon className={classes.plusIcon}/>
                    </a>
                </CustomTooltip>
            </div>}
            {isLoading && <div className={classes.emptyPlusIconBox}></div>}
        </div>
    )
}
export default SlideOutline;