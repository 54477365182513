import { setIsLoading, setOutlineObj } from "Actions/outlineActions";
import { get, post } from "Scripts/api";
import { ApiErrorCodes } from "Scripts/globals";
import { useInterval } from "Scripts/intervalHook";
import { AIGenerateCheckStatus } from "Types/aiTypes";
import { MobileEditorTabs } from "Types/layoutTypes";
import { ISlideOutline } from "Types/outlineTypes";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Features } from "./useFeatures";
import { useLayout } from "./useLayout";
import { useTranslations } from "./useTranslations";

interface IGenerateOutline {
    flatInputObjValues: {[key: string]: any};
}

const useOutline = ()=>{
    const [isLoadingRewrite, setIsLoadingRewrite] = useState<boolean>(false);
    const [generatePresentationLoading, setGeneratePresentationLoading] = useState<boolean>(false);
    const [generateJobId, setGenerateJobId] = useState<string | null>(null);
    const [rewriteSuggestions, setRewriteSuggestions] = useState<ISlideOutline[]>([]);

    const outline = useSelector((state: RootStateOrAny) => (state.outlineReducer).outline);
    const isLoading = useSelector((state: RootStateOrAny) => (state.outlineReducer).isLoading);
    
    const { translatePlaceholder } = useTranslations();
    const dispatch = useDispatch();
    const {
        setShowMissingFeature,
        setSelectedMobileEditorTab,
        setShowAiPresentationReadyDialog,
} = useLayout();

    const history = useHistory();

    const GENERATE_JOB_POLL_TIME_IN_MS = 4000;

    const setOutline = (outline: ISlideOutline[])=> {
        dispatch(setOutlineObj(outline));
    }

    const setIsLoadingState = (isLoading: boolean)=> {
        dispatch(setIsLoading(isLoading));
    }

    const generateOutline = async ({ flatInputObjValues }: IGenerateOutline)=> {
        if(!flatInputObjValues) return;
        setIsLoadingState(true);

        if(!flatInputObjValues.sourceType){
            flatInputObjValues = {
                ...flatInputObjValues,
                ['sourceType']: "keywords",
            }
        }
        if(!flatInputObjValues.keywords){
            flatInputObjValues = {
                ...flatInputObjValues,
                ['keywords']: flatInputObjValues?.title,
            }
        }
        if(!flatInputObjValues.outputType){
            flatInputObjValues = {
                ...flatInputObjValues,
                ['outputType']: "presentation",
            }
        }

        try{
            const response = await post('generate/outline', flatInputObjValues);
            if (response.error && response.error === ApiErrorCodes.AiPresentationLimitReached){
                setIsLoadingState(false);
                setShowMissingFeature(Features.GENERATE_AI_PRESENTATIONS);
            } else if (response.error && response.error === ApiErrorCodes.NoFeatureAllAiLanguages){
                setIsLoadingState(false);
                setShowMissingFeature(Features.ALL_AI_LANGUAGES);
            } else if (Boolean(response.jobId)) {
                // Trigger polling (useInterval)
                setGenerateJobId(response.jobId);
            }
            else {
                setIsLoadingState(false);
                enqueueSnackbar(translatePlaceholder("SERVICES_AT_HIGH_CAPACITY"), { variant: 'error' });
            }
        } catch (error) {
            setIsLoadingState(false);
            console.warn({
                error
            })
        };
    };

    const generateRewriteOutline = async (rewriteOutline: ISlideOutline[]|{[key: string]: any}[])=> {
        setIsLoadingRewrite(true);
        if(!rewriteOutline.sourceType){
            rewriteOutline = {
                ...rewriteOutline,
                ['sourceType']: "keywords",
            }
        }
        if(!rewriteOutline.keywords){
            rewriteOutline = {
                ...rewriteOutline,
                ['keywords']: rewriteOutline?.outline?.find((t)=> t?.type == "title")?.title,
            }
        }
        if(!rewriteOutline.outputType){
            rewriteOutline = {
                ...rewriteOutline,
                ['outputType']: "presentation",
            }
        }

        try{
            const response = await post('generate/outline/rewrite', rewriteOutline);
            if (response.error){
                setIsLoadingRewrite(false);
               //TODO: show snackbar error with some error message
            } else if (Boolean(response.jobId)) {
                // Trigger polling (useInterval)
                setGenerateJobId(response.jobId);
            }
            else {
                setIsLoadingRewrite(false);
                enqueueSnackbar(translatePlaceholder("SERVICES_AT_HIGH_CAPACITY"), { variant: 'error' });
            }
        } catch (error) {
            setIsLoadingRewrite(false);
            console.warn({
                error
            })
        };
    };

    const generateOutlineProcess = async (finalOutline: ISlideOutline[]|{[key: string]: any}[])=> {
        setGeneratePresentationLoading(true);
        try{
            const response = await post('generate/outline/process', finalOutline);
            if (response.error){
                setGeneratePresentationLoading(false);

                if(response.error === ApiErrorCodes.AiPresentationLimitReached) {
                    setShowMissingFeature(Features.GENERATE_AI_PRESENTATIONS);
                    return;
                }

               //TODO: show snackbar error with some error message
            } else if (Boolean(response.jobId)) {
                // Trigger polling (useInterval)
                setGenerateJobId(response.jobId);
            }
            else {
                setGeneratePresentationLoading(false);
                enqueueSnackbar(translatePlaceholder("SERVICES_AT_HIGH_CAPACITY"), { variant: 'error' });
            }
        } catch (error) {
            setGeneratePresentationLoading(false);
            console.warn({
                error
            })
        };
    };

    const checkJobStatus = async () => {
        if (generateJobId === undefined || generateJobId === null) {
            return;
        }
        const response = await get(`generate/check-queue-job-status/${generateJobId}`);
        
        if (response && Boolean(response.status)) {
            if (response.status === AIGenerateCheckStatus.Queued) {
                // Should change to working pretty soon... lets wait a bit longer
                // We could implement something to detect being queued to long
            }
            else if (response.status === AIGenerateCheckStatus.Working) {
                // Still working, lets wait a bit longer
            }
            else {
                // For other situations we need to stop polling and close the wait window
                setGenerateJobId(null);

                if (response.status === AIGenerateCheckStatus.Done && Boolean(response.result.outline)) {
                    setIsLoadingState(false);
                    setOutline(response.result.outline);
                    //TODO: add success snackbar???
                }
                if (response.status === AIGenerateCheckStatus.Done && Boolean(response.result.suggestions)) {
                    setRewriteSuggestions(response.result.suggestions);
                    setIsLoadingRewrite(false);
                    //TODO: add success snackbar???
                }
                if (response.status === AIGenerateCheckStatus.Done && Boolean(response.result.outputType)) {
                    setGeneratePresentationLoading(false);
                    history.push(`/editor/${response.result.presentationId}`);
                    setSelectedMobileEditorTab(MobileEditorTabs.Slides);
                    setShowAiPresentationReadyDialog(true);
                    localStorage.removeItem('outlineData');
                    localStorage.removeItem('flatStepperData');
                    //TODO: add success snackbar???
                }
                else {
                    setIsLoadingState(false);
                    setIsLoadingRewrite(false);
                    setGeneratePresentationLoading(false);
                    //TODO: add generic error snackbar
                }
            }
        }
      }

    useInterval(() => {
        checkJobStatus();
        }, generateJobId ? GENERATE_JOB_POLL_TIME_IN_MS : null
    );

    return {
        outline,
        rewriteSuggestions,
        isLoading,
        isLoadingRewrite,
        generatePresentationLoading,
        setOutline,
        generateOutline,
        generateRewriteOutline,
        generateOutlineProcess,
    }
};
export default useOutline;