import { AnyAction } from "redux";

export const initialState = {
    outline: [],
    isLoading: false,
    slideOutlineSelected: null,
    dialogTypeObj: {dialogType: "", index: 0, isOpen: false},
}

export enum OutlineActionTypes {
    SetOutline = 'SET_OUTLINE',
    SetIsLoading = 'SET_IS_LOADING',
    SetSlideOutlineSelected = 'SET_SLIDE_OUTLINE_SELECTED',
    SetDialogTypeObj = 'SET_DIALOG_TYPE_OBJ',
}

export const outlineReducer = (state = initialState, { type, payload }: AnyAction) => {
    switch (type) {
        case OutlineActionTypes.SetOutline: {
            return {
                ...state,
                outline: payload
            }
        }
        case OutlineActionTypes.SetIsLoading: {
            return {
                ...state,
                isLoading: payload
            }
        }
        case OutlineActionTypes.SetSlideOutlineSelected: {
            return {
                ...state,
                slideOutlineSelected: payload
            }
        }
        case OutlineActionTypes.SetDialogTypeObj: {
            return {
                ...state,
                dialogTypeObj: payload
            }
        }
        default:
            return state
    }
}
