import { AppState } from "Types/appTypes";
import { useSelector } from "react-redux";

export const useUser = () => {
    const currentUser = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).currentUser);
    const isSendStepsUser = currentUser?.email.endsWith('@sendsteps.com');
    
    return {
        isSendStepsUser,
    };
};