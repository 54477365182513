
export const addToCookies = (cookieKey: string, cookieValue: string, expires: number, domain?: string) => {

    if (!domain) {

        const hostname = window.location.hostname.replace('www', '')

        if(hostname.includes('.')) {

            const splitDomains = hostname.split('.');

            domain = `${splitDomains[splitDomains.length - 2] || ``}.${splitDomains[splitDomains.length - 1]}`

        } else {

            domain = hostname;
        }
    }
    document.cookie = `${cookieKey}=${cookieValue}; Expires=${new Date(expires).toUTCString()}; Path=/; Domain=${domain}`;
}

export const getFromCookies = (cookieKey: string) => {

    const cookies = document.cookie.split(';');

    const cookieList = {}

    cookies.forEach(function (cookie) {

        var splitCookie = cookie.split('=');

        if(Array.isArray(splitCookie) && splitCookie.length >= 2) {

            cookieList[splitCookie[0].trim()] = splitCookie[1].trim()
        }
    });

    return cookieList[cookieKey] || null;
}

export const removeFromCookies = (cookieKey: string) => {

    addToCookies(cookieKey, "", -1);
}